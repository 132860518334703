import MasterCard from 'assets/images/card/mastercardBlack.svg';
import VisaCard from 'assets/images/card/visaBlack.png';
import AmericanExpressCard from 'assets/images/card/americanExpressCard.png';
import NoCard from 'assets/images/cardBg.png';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import { ReactComponent as RightArrow } from '../assets/icons/right-top.svg';
import {
  AudioOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FileJpgOutlined,
  FilePdfOutlined,
  UpCircleFilled,
  VideoCameraOutlined,
} from '@ant-design/icons';
import CurrencyFormat from 'react-currency-format';

export const cellType = {
  CHECKBOX: 'checkbox',
  STRING: 'string',
  DOUBLE_STRING: 'double-string',
  MODIFY: 'modify',
  STRING_BOLD: 'string-bold',
  STRING_BADGE: 'string-badge',
  BADGE: 'badge',
  SELECTION: 'selection',
  DOTTED_BADGE: 'dotted-badge',
  EXPANDER: 'expander',
  PROGRESS_BAR: 'progress-bar',
  LINE_STATUS: 'line-status',
  AVATAR: 'avatar',
  AVATAR_LIST: 'avatar-list',
  DOUBLE_BADGE: 'double-badge',
  FILE: 'file',
};

const ICONS = {
  pdf: <FilePdfOutlined />,
  jpg: <FileJpgOutlined />,
  jpeg: <FileJpgOutlined />,
  png: <FileImageOutlined />,
  xlsx: <FileExcelOutlined />,
  mp4: <VideoCameraOutlined />,
  mpeg: <AudioOutlined />,
  'text/csv': <FileExcelOutlined />,
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet': <FileExcelOutlined />,
  xls: <FileExcelOutlined />,
};

export const validatePassword = (password) => {
  const passwordRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]*)(?=.*[!@#$%^&*_-])(?=.{8,})',
  );
  return passwordRegex.test(password);
};

export const removeSpaces = (str) => {
  return str.replace(/\s/g, '');
};

export const convertNaNToZero = (value) => {
  return isNaN(value) ? 0 : value;
};

export const checkForManageWord = (str) => {
  const regex = /\manage\b/g;
  return regex.test(str);
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getCurrency = (name) => {
  switch (name) {
    case 'EUR':
      return '€';
    case 'ZAR':
      return 'R';
    case 'USD':
      return '$';
    case 'RWF':
      return 'RF';
    case 'XOF':
      return 'CFA';
    case 'NGN':
      return '₦';
    case 'GHS':
      return 'GH¢';
    case 'KES':
      return 'K';
  }
};

/**
 * Get sum of the values of the passed key in the passed array
 * @param array
 * @param keyName
 * @returns {number}
 */
export const getTotalAmount = (array = [], keyName) => {
  if (!(Array.isArray(array) && array.length)) return 0;
  return array.reduce((total, data) => {
    return +total + (+data[keyName] || 0);
  }, 0);
};

export const getAvailableBalance = (arrayItems, type) => {
  if (!Array.isArray(arrayItems)) return [];
  return arrayItems?.map((item) => {
    return {
      value: type === 'budgets' ? item.code : item?.type,
      name: item?.name,
      amount: item?.balance / 100,
      label: (
        <div>
          {type === 'budgets' ? item.name : 'Main Account'}
          <br />
          <span>
            <CurrencyFormat
              prefix={getCurrency(item?.currency)}
              value={item?.balance / 100}
              displayType="text"
              thousandSeparator={true}
            />
          </span>
        </div>
      ),
    };
  });
};

export const getCard = (title) => {
  switch (title) {
    case 'mastercard':
      return <img src={MasterCard} alt="MasterCard" className="w-100" />;
    case 'visa':
      return <img src={VisaCard} alt="VisaCard" className="w-100" />;
    case 'americanexpress':
      return <img src={AmericanExpressCard} alt="AmericanExpress" className="w-100" />;
    case 'verve':
      return <img src={VerveCard} alt="Verve" className="w-100" />;
    default:
      return <img src={NoCard} alt="NoCard" className="w-100" />;
  }
};

export const getColor = (title = '') => {
  if (!title) return '';
  switch (title?.toLocaleLowerCase()) {
    case 'success':
    case 'approved':
    case 'processed':
    case 'completed':
    case 'active':
    case 'paid':
    case 'partially paid':
      return '#12b76a';
    case 'declined':
    case 'expired':
    case 'rejected':
    case 'failed':
    case 'overdue':
      return '#C11574';
    case 'pending':
      return '#B54708';
    case 'physical':
    case 'member':
    case 'batch':
      return '#363F72';
    case 'approved':
      return '#F0ECF9';
    case 'virtual':
      return '#ECF3F9';
    case 'manager':
      return '#3538CD';
    case 'everyone':
      return '#F3F4F6';
    case 'processing':
    case 'verifying':
    case 'in review':
      return '#885A1A';
    case 'archived':
      return '#885A1A';
    case 'inactive':
    case 'draft':
    case 'scheduled':
    case 'invited':
    case 'Scheduled':
      return '#44403C';
    case 'freeze':
      return '#FFFACE';
    case 'employee':
      return '#5925DC';
    case 'bill':
      return '#B93815';
    case 'deleted':
      return '#ffcece';
    case 'admin':
      return '#175CD3';
    case 'disabled':
    case 'blocked':
    case 'cancelled':
    case 'paused':
    case 'unverified':
      return '#B42318';
    case 'verified':
    case 'out of pocket':
      return '#363F72';
    case 'invoice':
    case 'imported':
      return '#026AA2';
    case 'unverified':
      return '#fff1e6';
    case 'pause':
      return '#ecdeca';
    case 'flash':
      return '#F2F4F1';
    case 'payment':
      return '#7FB705';
    case 'debit':
      return '#000000';
    case 'credit':
      return '#039855';
    case 'overspent':
      return '#D92D20';
    case 'withinrange':
      return '#039855';
    case 'out-of-policy':
      return '#B54708';
  }
};

export const getSign = (title = '') => {
  switch (title.toLocaleLowerCase()) {
    case 'debit':
      return `-`;
    case 'credit':
      return `+`;
  }
};

export const getMethod = (title = '') => {
  switch (title.toLocaleLowerCase()) {
    case 'withdrawal':
      return 1;
    case 'top up':
      return 2;
    case 'closure':
      return 3;
  }
};

export const getInternationalFormat = (countryCode, localFormat) => {
  if (!countryCode || !localFormat) return '';
  let localFormatWithoutSpace = localFormat.replace(/\W+/g, '').toString();
  let newLocalFormat =
    countryCode === 234 && localFormatWithoutSpace.length === 11
      ? localFormatWithoutSpace.substring(1)
      : localFormat;

  return '+' + (countryCode + newLocalFormat).replace(/\s/g, '');
};

export const validateNigeriaNumber = (internationalFormat) => {
  if (internationalFormat) {
    const countryCode = internationalFormat.substring(0, 4);
    const phone = internationalFormat.substring(4);
    const phoneLength =
      phone.charAt(0) === '0' ? phone.length === 11 : phone.length === 10;
    if (countryCode === '+234') return !!phoneLength;
    return true;
  }
};

export const currencySign = ['NGN'];
export const cardCurrencySign = ['NGN', 'USD'];

export function capitalizeFirstLetter(string) {
  return string && String(string)?.charAt(0)?.toUpperCase() + String(string)?.slice(1);
}

export const truncateText = (text, start, end) => {
  return text?.length > end ? `${text?.substr(start, end)}...` : text;
};

export const BUDGETS_TYPES = {
  1: 'One time',
  2: 'Recurring',
  3: 'Auto top up',
};
/**
 * Get percentage of base
 * @param base
 * @param value
 * @returns {number} the percentage of base that value represents
 */
export function getPercentage(base, value) {
  return Math.round((value * 100) / base);
}

export function buildChartDataFromTransaction(transactions) {
  return Object.entries(transactions).map(([key, { amount }]) => ({
    name: key,
    total: amount,
  }));
}

export function buildTransactionTableData(transactions = [], isOverView = false) {
  const updatedData = transactions.slice(0, isOverView ? 5 : transactions.length);
  return updatedData.map((transaction) => ({
    transactionData: transaction,
    company: transaction.company.name,

    payer: `${transaction?.payer?.firstName} ${transaction?.payer?.lastName}`,
    amount: (
      <CurrencyFormat
        prefix={getCurrency(transaction.currency)}
        value={transaction.amount / 100}
        displayType="text"
        thousandSeparator={true}
      />
    ),
    budget: (transaction.budget && transaction.budget.name) || '-',
    card: transaction?.card
      ? `${transaction?.card?.brand}${transaction?.card?.pan.slice(
          transaction?.card?.pan.length - 6,
        )}`
      : 'Bank transfer',
    card_type: {
      value: transaction?.card?.type || '-',
      color: getColor(transaction?.card?.type),
    },
    // card_type: transaction?.card?.type,
    // amount: transaction.amount,
    // business: business,
    // {
    //   type: 'beneficiary',
    //   code: transaction.payer ? transaction.payer.code : null,
    //   value: transaction.by
    //     ? `${transaction.payer.firstName} ${transaction.payer.lastName}`
    //     : 'N/A',
    //   icon: transaction.payer ? <RightArrow /> : null,
    // },
    // card_bank: transaction.business ? `Card Payment` : 'Bank Payment',
    type: transaction?.type ? transaction?.type : 'N/A',
    // business: transaction.card ? `Card Payment` : 'Robotics Labs',
    // budget: {
    //   type: 'budget',
    //   code: transaction.budget ? transaction.budget.code : null,
    //   value: transaction.budget ? transaction.budget.name : 'N/A',
    //   icon: transaction.budget ? <RightArrow /> : null,
    //   link: transaction.budget ? `budgets/${transaction.budget.code}/overview` : null,
    // },
    // category: 'Entertainment',
    // card_bank: format(new Date(transaction.created_at), 'MMM dd, HH:mm aaa'),
    date: getFormattedDate(transaction.created_at),
    // date: format(new Date(transaction.created_at), 'MMM dd, HH:mm aaa'),
    status: { value: transaction.status, color: getColor(transaction.status) },
  }));
}

export function buildDemoRequestTableData(transactions = [], isOverView = false) {
  const updatedData = transactions.slice(0, isOverView ? 5 : transactions.length);
  return updatedData.map((transaction) => ({
    transactionData: transaction,
    company: transaction.company.name,

    payer: `${transaction?.payer?.firstName} ${transaction?.payer?.lastName}`,
    amount: (
      <CurrencyFormat
        prefix={getCurrency(transaction.currency)}
        value={transaction.amount / 100}
        displayType="text"
        thousandSeparator={true}
      />
    ),
    card: transaction?.card
      ? `${transaction?.card?.brand}${transaction?.card?.pan.slice(
          transaction?.card?.pan.length - 6,
        )}`
      : 'N/A',
    card_type: {
      value: transaction?.card?.type,
      color: getColor(transaction?.card?.type),
    },
    // card_type: transaction?.card?.type,
    // amount: transaction.amount,
    // business: business,
    // {
    //   type: 'beneficiary',
    //   code: transaction.payer ? transaction.payer.code : null,
    //   value: transaction.by
    //     ? `${transaction.payer.firstName} ${transaction.payer.lastName}`
    //     : 'N/A',
    //   icon: transaction.payer ? <RightArrow /> : null,
    // },
    // card_bank: transaction.business ? `Card Payment` : 'Bank Payment',
    type: transaction?.type ? transaction?.type : 'N/A',
    // business: transaction.card ? `Card Payment` : 'Robotics Labs',
    // budget: {
    //   type: 'budget',
    //   code: transaction.budget ? transaction.budget.code : null,
    //   value: transaction.budget ? transaction.budget.name : 'N/A',
    //   icon: transaction.budget ? <RightArrow /> : null,
    //   link: transaction.budget ? `budgets/${transaction.budget.code}/overview` : null,
    // },
    // category: 'Entertainment',
    // card_bank: format(new Date(transaction.created_at), 'MMM dd, HH:mm aaa'),
    date: getFormattedDate(transaction.created_at),
    // date: format(new Date(transaction.created_at), 'MMM dd, HH:mm aaa'),
    status: { value: transaction.status, color: getColor(transaction.status) },
  }));
}

export function buildBudgetTableData(budgets = [], isOverView = false) {
  const updatedData = budgets.slice(0, isOverView ? 5 : budgets.length);
  return updatedData.map((budget) => ({
    budgetData: budget,
    companyName: budget.company.name ? budget.company.name : 'N/A',
    type: BUDGETS_TYPES[budget.type],
    assignedUsers: budget.beneficiaries?.length || 'No one',
    beneficiaries: budget.beneficiaries,
    amount: (
      <CurrencyFormat
        prefix={getCurrency(budget.currency)}
        value={budget.amount / 100}
        displayType="text"
        thousandSeparator={true}
      />
    ),
    nameOfCreator: budget?.user
      ? `${budget?.user?.firstName} ${budget?.user?.lastName}`
      : 'N/A',
    name: budget?.name,
    creationDate: budget.created_at ? getFormattedDate(budget.created_at) : 'N/A',
    status: { value: budget.status, color: getColor(budget.status) },
  }));
}

export function buildBeneficiaryTableData(beneficiaries, budget = null) {
  return beneficiaries.map((beneficiary) => ({
    role: { value: beneficiary.user.role, color: getColor(beneficiary.user.role) },
    name: `${beneficiary.user.firstName} ${beneficiary.user.lastName}`,
    contact: beneficiary.user.email,
    assignedBudget: {
      type: 'assignedBudget',
      code: budget.code,
      value: budget.name,
      number: '+3',
      icon: <UpCircleFilled />,
    },
    totalSpent: `${getCurrency(budget.currency)}${beneficiary.spent}`,
    dateAdded: format(new Date(beneficiary.created_at), 'MMM dd, HH:mm aaa'),
    status: { value: beneficiary.status, color: getColor(beneficiary.status) },
  }));
}

export function buildReimbursementTableData(reimbursement = [], isOverView = false) {
  const updatedData = reimbursement.slice(0, isOverView ? 5 : reimbursement.length);
  return updatedData.map((reimbursement) => ({
    transactionData: reimbursement,
    creationDate: format(new Date(reimbursement.created_at), 'MMM dd, HH:mm aaa'),
    by: {
      type: 'beneficiary',
      code: reimbursement.user ? reimbursement.user.code : null,
      value: reimbursement.user
        ? `${reimbursement.user.firstName} ${reimbursement.user.lastName}`
        : 'N/A',
      icon: reimbursement.user ? <RightArrow /> : null,
    },
    amount: (
      <CurrencyFormat
        prefix={getCurrency(reimbursement.currency)}
        value={reimbursement.amount / 100}
        displayType="text"
        thousandSeparator={true}
      />
    ),
    budget: {
      type: 'budget',
      code: reimbursement.budget ? reimbursement.budget.code : null,
      value: reimbursement.budget ? reimbursement.budget.name : 'N/A',
      icon: reimbursement.budget ? <RightArrow /> : null,
      // link: transaction.budget ? `budgets/${transaction.budget.code}/overview` : null,
    },
    vendor: reimbursement.vendor ? reimbursement.vendor.name : 'N/A',
    description: reimbursement.description ? reimbursement.description : 'N/A',
    receipt: {
      type: 'receipt',
      code: reimbursement.receipt ? reimbursement.receipt : null,
      value: reimbursement.receipt ? reimbursement.receipt : 'N/A',
      icon: reimbursement.receipt ? <RightArrow /> : null,
    },
    // date: format(new Date(transaction.created_at), 'MMM dd, HH:mm aaa'),
    status: { value: reimbursement.status, color: getColor(reimbursement.status) },
  }));
}

export function buildCompaniesTableData(companies = [], isOverView = false) {
  const updatedData = companies.slice(0, isOverView ? 5 : companies.length);
  return updatedData.map((company) => ({
    companiesData: company,
    name: company?.name ? company?.name : 'N/A',
    //owner_name: company?.Users[0] ? `${company?.Users[0].firstName} ${company?.Users[0].lastName}` : 'N/A',
    contact_email: (company?.contact_email || company?.users[0]?.email) ?? 'N/A',
    contact_phone:
      company?.contact_phone && company?.contact_phone !== 'undefinedundefined'
        ? `+${company?.contact_phone}`
        : 'N/A',
    website: company?.website ? company?.website : 'N/A',
    // receipt: reimbursement?.receipt ? <>{reimbursement?.receipt?.name } <RightArrow onClick={() => openReceipt(reimbursement?.receipt?.code)}/></>  : 'N/A',
    creationDate: getFormattedDate(company?.created_at),
    status: { value: company?.onboardingStatus, color: getColor(company?.onboardingStatus) },
    size: company.size || 'Unknown',
  }));
}

export function buildKYBTableData(kyb = [], isOverView = false) {
  const updatedData = kyb.slice(0, isOverView ? 5 : kyb.length);
  return updatedData.map((kyb) => ({
    kybData: kyb,
    businessname: kyb?.name ? kyb?.name : 'N/A',
    bussinesstype: kyb?.businessType ? kyb?.businessType : 'N/A',
    address: kyb?.address?.street ? kyb?.address?.street : 'N/A',
    businessemail: kyb?.contact_email ? kyb?.contact_email : 'N/B',
    businessphone:
      kyb?.contact_phone && kyb?.contact_phone !== 'undefinedundefined'
        ? `+${kyb?.contact_phone}`
        : 'N/A',
    creationdate: getFormattedDate(kyb?.created_at),
    status: { value: kyb?.status, color: getColor('kyb?.status') },
  }));
}

export function buildBusinessesData(businesses = [], isOverView = false) {
  const updatedData = businesses.slice(0, isOverView ? 5 : businesses.length);
  return updatedData.map((business) => ({
    kybData: business,
    businessname: business?.businessName ? business?.businessName : 'N/A',
    businessaddress: business?.businessAddress ? business?.businessAddress : 'N/A',
    businessemail: business?.businessEmail ? business?.businessEmail : 'N/A',
    businessphone: business?.businessPhone ? business?.businessPhone : 'N/A',
    ownername: business?.businessType ? business?.businessType : 'N/A',
    submissiondate: format(new Date(business?.submissionDate), 'MMM dd, HH:mm aaa'),
    status: { value: business?.status, color: getColor(business?.status) },
    size: business?.size || 'Unknown',
  }));
}

export function buildUsersTableData(users = [], isOverView = false) {
  const updatedData = users.slice(0, isOverView ? 5 : users.length);
  return updatedData.map((users) => ({
    userData: users,
    companyname: users?.company?.name || 'N/A',
    name: users?.firstName ? `${users?.firstName} ${users?.lastName}` : 'N/A',
    email: users?.email ? users?.email : 'N/A',
    budget: users?.budgetCount || 0,
    // role: users?.role ? users?.role : 'N/A',
    role: { value: users?.role, color: getColor(users?.role) },
    creationdate: getFormattedDate(users?.created_at),
    status: { value: users?.status, color: getColor(users?.status) },
  }));
}

export function buildEmployeeReimbursementTableData(
  reimbursement = [],
  isOverView = false,
) {
  const updatedData = reimbursement.slice(0, isOverView ? 5 : reimbursement.length);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const openReceipt = (code) => {};
  return updatedData.map((reimbursement) => ({
    transactionData: reimbursement,
    creationDate: format(new Date(reimbursement?.created_at), 'MMM dd, HH:mm aaa'),
    amount: (
      <CurrencyFormat
        prefix={getCurrency(reimbursement?.currency)}
        value={reimbursement?.amount / 100}
        displayType="text"
        thousandSeparator={true}
      />
    ),
    vendor: reimbursement?.vendor ? reimbursement?.vendor?.name : 'N/A',
    description: reimbursement?.description ? reimbursement?.description : 'N/A',
    // receipt: reimbursement?.receipt ? <>{reimbursement?.receipt?.name } <RightArrow onClick={() => openReceipt(reimbursement?.receipt?.code)}/></>  : 'N/A',
    receipt: {
      type: 'receipt',
      code: reimbursement?.receipt?.code ? reimbursement?.receipt?.code : null,
      value: reimbursement?.receipt?.name ? reimbursement?.receipt?.name : 'N/A',
      // icon: reimbursement?.receipt ? <RightArrow onClick={() => openReceipt(reimbursement?.receipt?.code)}/> : null,
    },
    status: { value: reimbursement?.status, color: getColor(reimbursement?.status) },
  }));
}

export function buildDirectorsTableData(directors = []) {
  return directors.map((director) => ({
    directorsData: director,
    name: `${director?.firstName} ${director?.lastName}`,
    email: director.email,
    percentage: director.percentageOwned ? `${director.percentageOwned}%` : '-',
    evidence: ICONS[director?.documents?.length && 'pdf'] || '-',
    modify: true,
  }));
}

/**
 * Title a string
 * It makes the first char upperCase
 * e.g toTitle('hello') returns 'Hello'
 * @param title
 * @returns {string}
 */
export function toTitle(title) {
  return `${title.charAt(0).toUpperCase()}${title.slice(1)}`;
}

export const states = [
  {
    value: 'FC',
    label: 'FCT',
  },
  {
    value: 'AB',
    label: 'Abia',
  },
  {
    value: 'AD',
    label: 'Adamawa state',
  },
  {
    value: 'AK',
    label: 'AkwaIbom',
  },
  {
    value: 'AN',
    label: 'Anambra',
  },
  {
    value: 'BA',
    label: 'Bauchi',
  },
  {
    value: 'BY',
    label: 'Bayelsa',
  },
  {
    value: 'BE',
    label: 'Benue',
  },
  {
    value: 'BO',
    label: 'Borno',
  },
  {
    value: 'CR',
    label: 'CrossRiver',
  },
  {
    value: 'DE',
    label: 'Delta',
  },
  {
    value: 'EB',
    label: 'Ebonyi',
  },
  {
    value: 'ED',
    label: 'Edo',
  },
  {
    value: 'EK',
    label: 'Ekiti',
  },
  {
    value: 'EN',
    label: 'Enugu',
  },
  {
    value: 'GO',
    label: 'Gombe',
  },
  {
    value: 'IM',
    label: 'Imo',
  },
  {
    value: 'JI',
    label: 'Jigawa',
  },
  {
    value: 'KD',
    label: 'Kaduna',
  },
  {
    value: 'KN',
    label: 'Kano',
  },
  {
    value: 'KT',
    label: 'Katsina',
  },
  {
    value: 'KE',
    label: 'Kebbi',
  },
  {
    value: 'KO',
    label: 'Kogi',
  },
  {
    value: 'KW',
    label: 'Kwara',
  },
  {
    value: 'LA',
    label: 'Lagos',
  },
  {
    value: 'NA',
    label: 'Nassarawa',
  },
  {
    value: 'NI',
    label: 'Niger',
  },
  {
    value: 'OG',
    label: 'Ogun',
  },
  {
    value: 'ON',
    label: 'Ondo',
  },
  {
    value: 'OS',
    label: 'Osun',
  },
  {
    value: 'OY',
    label: 'Oyo',
  },
  {
    value: 'PL',
    label: 'Plateau',
  },
  {
    value: 'RI',
    label: 'Rivers',
  },
  {
    value: 'SO',
    label: 'Sokoto',
  },
  {
    value: 'TA',
    label: 'Taraba',
  },
  {
    value: 'YO',
    label: 'Yobe',
  },
  {
    value: 'ZA',
    label: 'Zamfara',
  },
];

export const Banks = [
  {
    label: 'Abbey Mortgage Bank',

    value: '801',
  },
  {
    label: 'Above Only MFB',

    value: '51204',
  },
  {
    label: 'Access Bank',
    value: '044',
  },
  {
    label: 'Access Bank (Diamond)',
    value: '063',
  },
  {
    label: 'ALAT by WEMA',
    value: '035A',
  },
  {
    label: 'Amju Unique MFB',
    value: '50926',
  },
  {
    label: 'ASO Savings and Loans',
    value: '401',
  },
  {
    label: 'Bainescredit MFB',
    value: '51229',
  },
  {
    label: 'Bowen Microfinance Bank',
    value: '50931',
  },
  {
    label: 'Carbon',
    value: '565',
  },
  {
    label: 'CEMCS Microfinance Bank',
    value: '50823',
  },
  {
    label: 'Citibank Nigeria',
    value: '023',
  },
  {
    label: 'Coronation Merchant Bank',
    value: '559',
  },
  {
    label: 'Ecobank Nigeria',
    value: '050',
  },
  {
    label: 'Ekondo Microfinance Bank',
    value: '562',
  },
  {
    label: 'Eyowo',
    value: '50126',
  },
  {
    label: 'Fidelity Bank',
    value: '070',
  },
  {
    label: 'Firmus MFB',
    value: '51314',
  },
  {
    label: 'First Bank of Nigeria',
    value: '011',
  },
  {
    label: 'First City Monument Bank',
    value: '214',
  },
  {
    label: 'FSDH Merchant Bank Limited',
    value: '501',
  },
  {
    label: 'Globus Bank',
    value: '00103',
  },
  {
    label: 'GoMoney',
    value: '100022',
  },
  {
    label: 'Guaranty Trust Bank',
    value: '058',
  },
  {
    label: 'Hackman Microfinance Bank',
    value: '51251',
  },
  {
    label: 'Hasal Microfinance Bank',
    value: '50383',
  },
  {
    label: 'Heritage Bank',
    value: '030',
  },
  {
    label: 'Ibile Microfinance Bank',
    value: '51244',
  },
  {
    label: 'Infinity MFB',
    value: '50457',
  },
  {
    label: 'Jaiz Bank',
    value: '301',
  },
  {
    label: 'Kadpoly MFB',
    value: '50502',
  },
  {
    label: 'Keystone Bank',
    value: '082',
  },
  {
    label: 'Kredi Money MFB LTD',
    value: '50200',
  },
  {
    label: 'Kuda Bank',
    value: '50211',
  },
  {
    label: 'Lagos Building Investment Company Plc.',
    value: '90052',
  },
  {
    label: 'Links MFB',
    value: '50549',
  },
  {
    label: 'Lotus Bank',
    value: '303',
  },
  {
    label: 'Mayfair MFB',
    value: '50563',
  },
  {
    label: 'Mint MFB',
    value: '50304',
  },
  {
    label: 'Paga',
    value: '100002',
  },
  {
    label: 'PalmPay',
    value: '999991',
  },
  {
    label: 'Parallex Bank',
    value: '104',
  },
  {
    label: 'Parkway - ReadyCash',
    value: '311',
  },
  {
    label: 'Paycom',
    value: '999992',
  },
  {
    label: 'Petra Mircofinance Bank Plc',
    value: '50746',
  },
  {
    label: 'Polaris Bank',
    value: '076',
  },
  {
    label: 'Providus Bank',
    value: '101',
  },
  {
    label: 'QuickFund MFB',
    value: '51293',
  },
  {
    label: 'Rand Merchant Bank',
    value: '502',
  },
  {
    label: 'Rubies MFB',
    value: '125',
  },
  {
    label: 'Sparkle Microfinance Bank',
    value: '51310',
  },
  {
    label: 'Stanbic IBTC Bank',
    value: '221',
  },
  {
    label: 'Standard Chartered Bank',
    value: '068',
  },
  {
    label: 'Sterling Bank',
    value: '232',
  },
  {
    label: 'Suntrust Bank',
    value: '100',
  },
  {
    label: 'TAJ Bank',
    value: '302',
  },
  {
    label: 'Tangerine Money',
    value: '51269',
  },
  {
    label: 'TCF MFB',
    value: '51211',
  },
  {
    label: 'Titan Bank',
    value: '102',
  },
  {
    label: 'Unical MFB',
    value: '50871',
  },
  {
    label: 'Union Bank of Nigeria',
    value: '032',
  },
  {
    label: 'United Bank For Africa',
    value: '033',
  },
  {
    label: 'Unity Bank',
    value: '215',
  },
  {
    label: 'VFD Microfinance Bank Limited',
    value: '566',
  },
  {
    label: 'Wema Bank',
    value: '035',
  },
  {
    label: 'Zenith Bank',
    value: '057',
  },
];

export const businessTypes = [
  { value: 'sole proprietorship', label: 'Sole Proprietorship' },
  { value: 'limited liability', label: 'Limited Liability' },
  { value: 'ngo', label: 'NGO' },
  // { value: 'enterprise', label: 'Enterprise' },
  // { value: 'partnership', label: 'Partnership' },
];

export const industry = [
  {
    label: 'Agriculture',
    value: 'idt_gnxq4u7ccooy8m9e3',
  },
  {
    label: 'Cryptocurrency',
    value: 'idt_k2nuc6sg5c5owf0b4',
  },
  {
    label: 'Digital Services',
    value: 'idt_h1e1cjaz3go3iriwg',
  },
  {
    label: 'E-Commerce',
    value: 'idt_sfw1e9kb7qdgeih11',
  },
  {
    label: 'Entertainment',
    value: 'idt_uqfwghq792l7vcjbq',
  },
  {
    label: 'Financial Services',
    value: 'idt_j0aw4kgpp892bdni6',
  },
  {
    label: 'FinTech',
    value: 'idt_ijzrxjc79buq7m1y8',
  },
  {
    label: 'General Services',
    value: 'idt_c9tsgmqlk1jmzg7fd',
  },
  {
    label: 'Hospitals and Health',
    value: 'idt_ulgokj2792daxoyq5',
  },
  {
    label: 'Software',
    value: 'idt_yrrm6eegi79zydea0',
  },
  {
    label: 'Health Tech',
    value: 'idt_720l8ku3o3hl5j8pn',
  },
  {
    label: 'Leisure and Entertainment',
    value: 'idt_8d2296vhdwsr95vmx',
  },
  {
    label: 'Membership Groups',
    value: 'idt_it2kqy662vtk0hku2',
  },
  {
    label: 'NGOs',
    value: 'idt_hfjs6gn144afoszx8',
  },
  {
    label: 'Press and Media',
    value: 'idt_2sh70zvi4znmxz41c',
  },
  {
    label: 'Religious Organizations',
    value: 'idt_ibtnhcfi9ckbmmbss',
  },
  {
    label: 'Restaurant and Food',
    value: 'idt_v6wkolf7w7gdukh43',
  },
  {
    label: 'Schools',
    value: 'idt_rv0v1e0yoosntnqmg',
  },
  {
    label: 'Travel & Hospitality',
    value: 'idt_jssjr619lqvijhrol',
  },
  {
    label: 'Utilities',
    value: 'idt_ov4qia857qkjvtax1',
  },
  {
    label: 'Others',
    value: 'idt_dcs19euw61k4hdsye',
  },
];

export function getFormattedDate(date) {
  const jsDate = new Date(date);
  const formatTemplate = isToday(jsDate) ? 'hh:mm aa' : 'MMM d, yyyy';
  return date && isNaN(date) ? format(jsDate, formatTemplate) : null;
}

export const getDocmentTypes = (type) => {
  switch (type) {
    case 'ip':
      return 'International Passport';
    case 'dl':
      return 'Drivers license';
    case 'vi':
      return 'Voters Card';
    case 'rcNumber':
      return 'RC Number';
    case 'nin':
      return 'National Identity Number';
    case 'C_of_T':
      return 'Certificate of Incorporated Trustees';
    case 'Scum_L_C':
      return 'SCUML Certificate';
    case 'cacITForm1':
      return 'CAC IT Form 1';
    case 'bvn':
      return 'BVN Number';
    case 'cac':
      return 'CAC Document';
    case 'bnNumber':
    case 'cacBn1':
      return 'BN Number';
    case 'utility-Bill':
      return 'Utility Bill';
    case 's_Doc':
      return 'CAC 2 / Share Allotment';
    case 'incorp_C':
    case 'cac':
      return 'Certificate of Incoporation';
    case 'cacForm1':
      return 'Certificate of Incoporation Form 1';
    case 'cacForm2':
      return 'Certificate of Incoporation Form 2';
    case 'cacForm1':
      return 'Certificate of Incoporation Form 3';
    case 'moa':
      return 'Memorandum of Association';
    case 'tin':
      return 'Tax Identification Number';
    case 'cacStatusReport':
      return 'CAC status report';
  }
};

export const getCountry = [
  {
    value: 'ctry_ivsxa2miqlc85daac',
    label: 'NIGERIA',
    iso_code: 'NG',
    alpha: 'NGA',
    currency: 'NGN',
  },
];

export const transactionAcceptedFiles = {
  'text/html': ['.xlsx'],
  'image/jpeg': ['.jpeg', '.jpg', '.png'],
  'application/pdf': ['.pdf'],
  'audio/*': ['.mp3'],
  'video/*': ['.mp4'],
};

export const transactionFileSupported =
  'Supported file types: jpeg, png, pdf, xlsx, mp4, mp3. Max file size: 5mb';

export const idType = [
  { value: 'nin', label: 'National ID Card' },
  { value: 'ip', label: 'International Passport' },
  { value: 'vi', label: 'Voter ID' },
  { value: 'dl', label: 'Driver License' },
];
