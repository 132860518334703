import React from 'react';
import './styles.scss';
import { getColor, truncateText, capitalizeFirstLetter } from 'utils/helper';
import cs from 'classnames';
import { Badge } from 'react-bootstrap';
import { ClockIcon } from 'assets/icons';
import Color from 'color';

const colorAliaser = (status) => {
  switch (status?.toLocaleLowerCase()) {
    case 'success':
    case 'approved':
    case 'processed':
    case 'completed':
    case 'paid':
    case 'active':
    case 'paid':
    case 'partially paid':
      return {
        bg: '#12b76a',
        border: '#12b76a',
      };

    case 'draft':
    case 'invited':
    case 'inactive':
    case 'uncategorized':
    case 'scheduled':
      return {
        bg: '#FAFAF9',
        border: '#E7E5E4',
      };

    case 'processing':
    case 'verifying':
    case 'in review':
      return {
        bg: '#FBF5EC',
        border: '#ECCC9E',
      };

    case 'pending':
      return {
        bg: '#FFFAEB',
        border: '#FEDF89',
      };

    case 'cancelled':
    case 'paused':
    case 'unverified':
      return {
        bg: '#FEF3F2',
        border: '#FECDCA',
      };

    case 'declined':
    case 'expired':
    case 'rejected':
    case 'failed':
    case 'overdue':
      return {
        bg: '#FDF2FA',
        border: '#FCCEEE',
      };

    case 'paid':
    case 'payment':
      return {
        bg: '#F5FFD9',
        border: '#EDFF9D',
      };

    case 'admin':
      return {
        bg: '#EFF8FF',
        border: '#B2DDFF',
      };

    case 'manager':
      return {
        bg: '#EEF4FF',
        border: '#C7D7FE',
      };

    case 'employee':
      return {
        bg: '#F4F3FF',
        border: '#D9D6FE',
      };

    case 'out of pocket':
    case 'batch':
      return {
        bg: '#F8F9FC',
        border: '#D5D9EB',
      };

    case 'invoice':
      return {
        bg: '#F0F9FF',
        border: '#B9E6FE',
      };
    case 'unverified':
      return {
        bg: '#FEF3F2',
        border: '#FECDCA',
      };

    case 'bill':
      return {
        bg: '#FEF6EE',
        border: '#F9DBAF',
      };

    default:
      return null;
  }
};
const BadgeType = ({ value, isBadge = true, margin }) => {
  const color = isBadge ? value?.color || getColor(value?.value) : '#44403C';
  const aliaser = colorAliaser(value?.value ?? 'uncategorized');

  return (
    <section>
      <div className="d-flex align-items-center">
        <span
          style={{
            backgroundColor: '#242628',
            border: `#242628`,
          }}
          className={cs('badge-wrapper', { 'mr-3 mb-1': margin })}
        >
          <>
            {value?.value === 'Scheduled' ? (
              <Badge
                className="badges-inherit"
                style={{ color: '#44403C', padding: '4px 8px' }}
              >
                <span style={{ marginRight: 4 }}>
                  <ClockIcon />
                </span>
                Scheduled
              </Badge>
            ) : (
              <Badge
                className="text-capitalize badges-inherit"
                style={{ color, padding: '4px 8px' }}
              >
                {value?.custom ? (
                  value?.custom
                ) : (
                  <>
                    {value?.icon && value.icon}
                    {value?.count && value.count}
                    {capitalizeFirstLetter(
                      truncateText(isBadge ? value?.value : value, 0, 25),
                    )}
                  </>
                )}
              </Badge>
            )}
          </>
        </span>
        {((typeof value?.number === 'number' && value?.number > 0) ||
          (typeof value?.number !== 'number' && value?.number)) && (
          <span className="ps-2 silver-color">{value.number}</span>
        )}
      </div>

      {/* Subtext Here */}
      {value?.subText && (
        <span
          className="pt-2"
          style={{ fontSize: 12, color: '#79716B', position: 'relative', top: 2 }}
        >
          {value?.subText}
        </span>
      )}
    </section>
  );
};
export default BadgeType;
